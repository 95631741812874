import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
// import logo from "../../image/logo-garudmart.png";
import styles from "./Navbar.css";
import { FcMenu } from "react-icons/fc";
import { AiOutlineClose } from "react-icons/ai";
import SideNavbar from "../SideNavbar/SideNavbar";
import logos from "../../image/garuda logog.png";
// import { FaUser } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { FiLogOut } from "react-icons/fi";
// import { RiUserFill } from "react-icons/ri";
import { logOut } from "../../redux/action/authAction";
import { FaUser } from "react-icons/fa";
// import { loadUser } from "../../redux/action/authAction";
// import { getSocialCategory } from "../../redux/action/socialCategoryAction";

// import { Link } from "react-scroll";

const Navbar = () => {
  const [hamberger, setHamberger] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.user);
  // console.log(user);

  const options = [
    { icon: <BsFillPersonFill />, name: "profile", func: account },
    { icon: <FiLogOut />, name: "logout", func: logout },
  ];

  if (user?.role === "admin" || user?.role === "moderator") {
    options.unshift({
      icon: <MdDashboard />,
      name: "dashboard",
      func: dashboard,
    });
  }

  function dashboard() {
    navigate("/dashboard");
  }

  function logout() {
    dispatch(logOut());
    window.location.assign("/login");
  }

  function account() {
    navigate("/profile");
  }

  useEffect(() => {}, [isAuthenticated]);

  return (
    <div className="navbar flex items-center justify-between  bg-saltpan h-[60px] pl-5 md:pl-10 md:pr-10 sticky top-0 z-10 shadow-lg font-OpenSans  text-black">
      <div className="navbar-start">
        <NavLink
          to="/"
          className="logo flex items-center normal-case  border-none"
        >
          <img className="h-[30px] md:h-[50px]" src={logos} alt="logo" />
        </NavLink>
      </div>
      <div className=" flex flex-col lg:block hidden lg:flex font-roboto opacity-75">
        <ul className="menu menu-horizontal font-normal text-base bg-saltpan ">
          <li
            className={({ isActive }) => (isActive ? `${styles.active}` : "")}
          >
            <NavLink to="/corporate" className="dropdown dropdown-hover">
              <label
                tabIndex={0}
                className="flex items-center  justify-center hover:text-red font-medium"
              >
                Corporate
                {/* <svg
                  className="ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="15"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg> */}
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content menu bg-saltpan top-12 left-0 p-2 rounded-box w-[200px] border-t border-5"
              >
                <li>
                  <Link to="/corporate/company-profile">Company Profile</Link>
                </li>
                <li>
                  <Link to="/corporate/business-overview">
                    Business Overview
                  </Link>
                </li>

                <li>
                  <Link to="/carrers">Carrers</Link>
                </li>
              </ul>
            </NavLink>
          </li>

          <li>
            <NavLink
              className="dropdown dropdown-hover"
            >
              <label
                tabIndex={0}
                className="flex items-center  justify-center  font-medium text-black"
              >
                Multi Steps
                {/* <svg
                  className="ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="15" 
                  viewBox="0 0 24 24"
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg> */}
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content menu top-12 left-0 p-2 bg-white  rounded-box w-[400px] border-t border-5 navMenu"
              >
                <li className="capitalize">
                  <Link to="/garuda-vega">
                    Garudavega business solutions private limited
                  </Link>
                </li>
                <li className="capitalize">
                  <Link to="/ward-wizard">
                    Wardwizard Innovations & Mobility Limited
                  </Link>
                </li>
                <li className="capitalize">
                  <Link to="/Mangalam-Industrial-Finance-Limited">
                  Mangalam Industrial Finance Limited
                  </Link>
                </li>
                <li className="capitalize">
                  <Link to="/garudayaan">Garudayaan logistics</Link>
                </li>
              </ul>
            </NavLink>
          </li>

          <li className=" flex justify-between items-center">
            <NavLink to="/gplus-premium-appliances" className="dropdown dropdown-hover">
              <label
                tabIndex={0}
                className="flex items-center justify-center hover:text-red font-medium"
              >
                Consumer Electronics
                {/* <svg
                  className="ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="15"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg> */}
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content menu bg-white top-12 left-0 p-2 shadow  rounded-box w-[250px] navMenu"
              >
                <li>
                  <Link to="/gplus-premium-appliances">Premium Appliances</Link>
                </li>
                {/* <li>
                  <Link to="/home-appliances">Home Appliances</Link>
                </li>
                <li>
                  <Link to="/kitechen-appliances">Kitchen Appliances</Link>
                </li> */}
              </ul>
            </NavLink>
          </li>

          <li className=" flex justify-between items-center">
            <NavLink to="/ebikes" className="dropdown dropdown-hover">
              <label
                tabIndex={0}
                className="flex items-center justify-center hover:text-red font-medium"
              >
                Joy e-bike
                {/* <svg
                  className="ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="15"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg> */}
              </label>
              {/* <ul
                tabIndex={0}
                className="dropdown-content menu bg-white top-12 left-0 p-2 shadow rounded-box w-[200px] navMenu"
              >
                <li>
                  <Link to="/mihos">Mihos</Link>
                </li>
                <li>
                  <Link to="/wolfplus">Wolf+</Link>
                </li>

                <li>
                  <Link to="/nanu">Gen Next Nanu +</Link>
                </li>

                <li>
                  <Link to="/glob">Glob</Link>
                </li>
                <li>
                  <Link to="/wolf">Wolf</Link>
                </li>
              </ul> */}
            </NavLink>
          </li>

          <li className=" flex justify-between items-center">
            <NavLink to="/ebikes" className="dropdown dropdown-hover">
              <label
                tabIndex={0}
                className="flex items-center justify-center hover:text-red capitalize font-medium"
              >
                Societal contributions
                {/* <svg
                  className="ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="15"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg> */}
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content menu bg-white top-12 left-0 p-2 shadow  rounded-box w-[200px] navMenu"
              >
                <li>
                  <Link to="/health-initiatives">Health Initiatives</Link>
                </li>
                <li>
                  <Link to="/environmental-sustainability">
                    Environmental Sustainability
                  </Link>
                </li>

                <li>
                  <Link to="/volunteer-programs">Volunteer Programs</Link>
                </li>

                <li>
                  <Link to="/charitable-donations">Charitable Donations</Link>
                </li>
              </ul>
            </NavLink>
          </li>

          {isAuthenticated ? (
            <div className="dropdown dropdown-hover flex items-center justify-center">
              <div className="flex  rounded p-1">
                <label
                  tabIndex={0}
                  className="flex items-center border rounded-full capitalize justify-center w-10 h-10 font-medium text-sm md:text-base text-black"
                >
                  {user?.email && user?.email.slice(0, 1)}
                </label>
              </div>

              <ul
                tabIndex={0}
                className="dropdown-content z-20 text-black menu  shadow bg-white rounded-box w-56 absolute right-0 top-10"
              >
                {options.map((item, index) => {
                  return (
                    <li className="" key={index}>
                      <NavLink
                        className="flex items-center justify-start font-normal text-sm capitalize"
                        to={`${item.name}`}
                        onClick={item.func}
                      >
                        {item.icon} <span className="ml-3">{item.name}</span>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <Link
              to="/login"
              className="font-medium text-base flex items-center justify-center space-x-1 capitalize  cursor-pointer"
            >
              <div className="flex items-center justify-center">
              <FaUser className="mr-3" size={20} />
              <span className="text-black"></span>
              </div>
            </Link>
          )}

          {/* <li
            className={({ isActive }) => (isActive ? `${styles.active}` : "")}
          >
            <NavLink className="font-medium" to="/contact">Contact Us</NavLink>
          </li> */}
        </ul>
      </div>

      <div className="flex absolute right-5 md:right-10 block lg:hidden ml-3 sm:ml-5 cursor-pointer ">
        {isAuthenticated ? (
            <div className="dropdown dropdown-hover flex items-center justify-center">
              <div className="flex  rounded p-1">
                <label
                  tabIndex={0}
                  // className="flex items-center border rounded-full capitalize justify-center pl-2 pr-2 sm:w-10 sm:h-10 font-medium text-sm md:text-base text-black"
                >
              <FaUser className="mr-3" size={20} />
              </label>
              </div>

              <ul
                tabIndex={0}
                className="dropdown-content z-20 text-black menu  shadow bg-white rounded-box w-56 absolute right-0 top-10"
              >
                {options.map((item, index) => {
                  return (
                    <li className="" key={index}>
                      <NavLink
                        className="flex items-center justify-start font-normal text-sm capitalize"
                        to={`${item.name}`}
                        onClick={item.func}
                      >
                        {item.icon} <span className="ml-3">{item.name}</span>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <Link
              to="/login"
              className="font-medium text-base flex items-center justify-center space-x-1 capitalize  cursor-pointer"
            >
              <div className="flex items-center justify-center">
              <FaUser className="mr-3" size={20} />
              <span className="text-black"></span>
              </div>
            </Link>
          )}
  
        <div
          className="flex  "
          onClick={() => setHamberger(!hamberger)}
        >
          {hamberger ? <AiOutlineClose size={25} /> : <FcMenu size={25} />}
        </div>
      </div>
      <div className="">
        {hamberger ? (
          <SideNavbar setHamberger={setHamberger} hamberger={hamberger} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Navbar;
