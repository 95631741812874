import React, { useEffect, useState } from "react";
import SideBar from "../../Dashboard/SideBar";
import { useDispatch, useSelector } from "react-redux";
import {
  createBlogs,
  getAllProductCategoryAction,
} from "../../../../redux/action/BlogsAction";
import { toast } from "react-toastify";
// import Editors from "./Editor";
import { GoPlus } from "react-icons/go";
import { HiMinusSm } from "react-icons/hi";
import MyEditor from "./Editors";

const BlogsCreate = () => {
  const [details, setDetails] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImageUpload] = useState();
  const [Error, setError] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [SEOTitle, setSEOTitle] = useState("");
  const [addMoreSeoTitile, setMoreSeoTitle] = useState("");
  const [SEODescription, setSEODescription] = useState("");
  const dispatch = useDispatch();
  const { success, error } = useSelector((state) => state.blogs);
  const { productCategory } = useSelector((state) => state.ProductCategory);
  // console.log(productCategory);
  // console.log(category);

  const addMoreSkills = (e) => {
    if (e.key === "comma") {
      e.preventDefault();
      setMoreSeoTitle([...SEOTitle, SEOTitle]);
    }
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();

    let errorMessage = {};

    if (!title) {
      errorMessage.title = "Title is required";
    }

    if (!category) {
      errorMessage.category = "Please select your blog category";
    }
    if (!details) {
      errorMessage.details = "add your blog content";
    }

    if (!image) {
      errorMessage.image = "Blog banner is required";
    }

    if (Object.keys(errorMessage).length > 0) {
      setError(errorMessage);
      return;
    }
    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("category", category);
    formdata.append("content", details);
    formdata.append("file", image);
    formdata.append("seoTitle", SEOTitle);
    formdata.append("seoDescription", SEODescription);

    dispatch(createBlogs(formdata));
  };

  useEffect(() => {
    if (success) {
      alert("Blog created successfull");
    }
    if (error) {
      toast(error);
    }
    dispatch(getAllProductCategoryAction());
  }, [dispatch, success, error]);

  return (
    <div className="flex">
      <SideBar />
      <div className="p-10">
        <div>
          <h1 className="uppercase font-semibold text-lg">Create Blogs</h1>
        </div>
        <form className="mt-10" onSubmit={onHandleSubmit}>
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="title"
                id="floating_first_name"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <label
                for="floating_first_name"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Title
              </label>
              {Error.title && <p className="text-red ">{Error.title}</p>}
            </div>
            <div>
              <select
                name=""
                id=""
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer uppercase"
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="Select product category">
                  Select product category
                </option>
                {productCategory &&
                  productCategory.map((cate) => {
                    return (
                      <option value={cate._id} className="capitalize">
                        {cate.name}
                      </option>
                    );
                  })}
              </select>
              {Error.category && <p className="text-red ">{Error.category}</p>}
            </div>

            <div className="flex flex-col w-full">
              <h1 className="mb-2 font-medium text-sm">Upload blog banner</h1>
              <label
                for="dropzone-file"
                // className="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                {/* <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold mr-1">
                      Click to upload your product banner
                    </span>
                   
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                  </p>
                </div> */}
                <input
                  id="dropzone-file"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  className=""
                  onChange={(e) => setImageUpload(e.target.files[0])}
                />
              </label>
              {Error.image && <p className="text-red ">{Error.image}</p>}
            </div>

            <div>
              <h1 className="font-medium text-sm capitalize">
                add your blog seo{" "}
              </h1>
              <h1
                onClick={() => setToggle(!toggle)}
                className="cursor-pointer flex items-center"
              >
                Advance{" "}
                <span className="ml-3">
                  {toggle ? <HiMinusSm size={30} /> : <GoPlus />}
                </span>
              </h1>
              {toggle ? (
                <div>
                  <div className="relative z-0 w-full mb-5 group mt-2">
                    <input
                      type="text"
                      name="title"
                      id="floating_first_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      value={SEOTitle}
                      onChange={(e) => setSEOTitle(e.target.value)}
                      onKeyDown={addMoreSkills}
                    />
                    <label
                      for="floating_first_name"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 "
                    >
                      SEO Post Title
                    </label>
                    <h1>{addMoreSeoTitile}</h1>
                  </div>
                  <div className="relative z-0 w-full mb-5 group">
                    <textarea
                      type="text"
                      name="title"
                      id="floating_first_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      value={SEODescription}
                      onChange={(e) => setSEODescription(e.target.value)}
                    />
                    <label
                      for="floating_first_name"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      SEO Post Description
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div
            className="w-full mt-5"
            style={{
              border: "1px solid black",
              padding: "10px",
              width: "100%",
              minHeight: "200px",
            }}
          >
            {/* <Editors setDetails={setDetails} /> */}
            <MyEditor setDetails={setDetails} />
            {Error.details && <p className="text-red ">{Error.details}</p>}
          </div>

          <button
            type="submit"
            className="bg-red text-white mt-10 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-20 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default BlogsCreate;
