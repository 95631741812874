import React, { useEffect, useState } from "react";
import SideBar from "../Dashboard/SideBar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCarrerByid, updateCarrerStatus } from "../../../redux/action/carresAction";
import { toast } from "react-toastify";

const GetApplicationById = () => {
  const dispatch = useDispatch();
  const { carrerId, loading } = useSelector((state) => state.carrerId);
  const { statusUpdate } = useSelector((state) => state.jobPosition);
  const [status, setUpdateStatus] = useState(carrerId?.status);
  const { id } = useParams();

  const applicationStatus = ["Under Review", "shortlisted", "Not shortlisted"];

  // console.log(carrerId);

  const onHandleSubmit = (e) =>{
    e.preventDefault()
    dispatch(updateCarrerStatus(id,{status}))
  }

  useEffect(() => {
    if(statusUpdate){
      toast.success("update successfull")
    }
    dispatch(getCarrerByid(id));
  }, [dispatch, id,statusUpdate]);

  return (
    <div className="flex font-Montserrat">
      <SideBar />
      <div className="p-5 w-full">
        {loading ? (
          <h1>Loading....</h1>
        ) : (
          <div>
            <div class="px-4 sm:px-0">
              <h3 class="text-base font-semibold leading-7 text-gray-900">
                Applicant Information
              </h3>
            </div>
            <div class="mt-6 border-t border-gray-100">
              <dl class="divide-y divide-gray-100">
                <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">
                    First Name
                  </dt>
                  <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {carrerId?.firstName}
                  </dd>
                </div>
                <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">
                    Last Name
                  </dt>
                  <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {carrerId?.LastName}
                  </dd>
                </div>
                <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">
                    Mobile No
                  </dt>
                  <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {carrerId?.Mobile}
                  </dd>
                </div>
                <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">
                    Application for
                  </dt>
                  <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {carrerId?.jobrole.name}
                  </dd>
                </div>
                <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">
                    Notice
                  </dt>
                  <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {carrerId?.Notice} days
                  </dd>
                </div>
                <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </dt>
                  <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {carrerId?.email}
                  </dd>
                </div>
                <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">
                    Current CTC
                  </dt>
                  <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {carrerId?.CurrentCTC}
                  </dd>
                </div>
                <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">
                    Expected CTC
                  </dt>
                  <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {carrerId?.ExpectedCTC}
                  </dd>
                </div>
                <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">
                    Skills
                  </dt>
                  <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {carrerId?.skills}
                  </dd>
                </div>

                <form onSubmit={onHandleSubmit}>
                  <h1 className="font-semibold text-lg mt-2">Application status</h1>
                  <div className="w-1/2 flex pb-10">
                    <select
                      name=""
                      id=""
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      onChange={(e) => setUpdateStatus(e.target.value)}
                    >
                      <option value="Select Event category" disabled>
                        Update Application Status
                      </option>
                      {applicationStatus &&
                        applicationStatus.map((cate) => {
                          return (
                            <option value={cate} className="capitalize">
                              {cate}
                            </option>
                          );
                        })}
                    </select>
                    <button
                      type="submit"
                      className="pl-10 pr-10 pt-1 pb-1 bg-red text-white rounded"
                    >
                      update
                    </button>
                    {/* {Error.SocialCategory && (
                <p className="text-red ">{Error.SocialCategory}</p>
              )} */}
                  </div>
                </form>

                {/* Employer */}
                <h1 className="pt-3 pb-3 font-semibold text-lg">Employer</h1>
                {carrerId?.Employer.map((emp) => {
                  return (
                    <>
                      <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">
                          Title
                        </dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {emp.designation}
                        </dd>
                      </div>
                      <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">
                          Company Name
                        </dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {emp.companyName}
                        </dd>
                      </div>
                      <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">
                          Location
                        </dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {emp.location}
                        </dd>
                      </div>
                      <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">
                          From
                        </dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {emp.startDate}
                        </dd>
                      </div>
                      <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">
                          To
                        </dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          {emp.check === true ? (
                            <h1>Currently working</h1>
                          ) : (
                            emp?.endDate
                          )}
                        </dd>
                      </div>
                    </>
                  );
                })}
                <h1 className="pt-3 pb-3 font-semibold text-lg">Attachments</h1>
                <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">
                    Attachments
                  </dt>
                  <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <ul class="divide-y divide-gray-100 rounded-md border border-gray-200">
                      <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                        <div class="flex w-0 flex-1 items-center">
                          <svg
                            class="h-5 w-5 flex-shrink-0 text-gray-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <div class="ml-4 flex min-w-0 flex-1 gap-2">
                            <span class="truncate font-medium">resume</span>
                            {/* <span class="flex-shrink-0 text-gray-400">2.4mb</span> */}
                          </div>
                        </div>
                        <div class="ml-4 flex-shrink-0">
                          <a
                            href={carrerId?.resume.url}
                            class="font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            Download
                          </a>
                        </div>
                      </li>
                      <li class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                        <div class="flex w-0 flex-1 items-center">
                          <svg
                            class="h-5 w-5 flex-shrink-0 text-gray-400"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <div class="ml-4 flex min-w-0 flex-1 gap-2">
                            <span class="truncate font-medium">
                              social Profile
                            </span>
                          </div>
                        </div>
                        {carrerId?.socialProfile &&
                          carrerId?.socialProfile.map((url) => {
                            return (
                              <div class="ml-4 flex-shrink-0">
                                <a
                                  href={url}
                                  class="font-medium text-indigo-600 hover:text-indigo-500"
                                >
                                  click
                                </a>
                              </div>
                            );
                          })}
                      </li>
                    </ul>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GetApplicationById;
