
import React from "react";
import gplusLogo from "../../image/$RBZAQ7Z.png"; 

const Gpluspage = () => {
  return (
    <div className="flex items-center  w-full h-[80px]  px-4 md:px-6 lg:px-8  bg-gradient-to-r from-black via-[#800000] via-60% to-white to-90%">
      {/* Gplus Logo */}
      <div  className="" >
      <img
        src={gplusLogo}
        alt="Gplus Logo"
        className="h-10 md:h-14 lg:h-16   object-contain"
      />
      </div>

      {/* Slogan */}
      <div>
      <h1 className="text-lg ml-[-5] sm:text-xl md:text-2xl lg:text-4xl font-extrabold text-[#F2DF93]  tracking-wide">
        INNOVATION YOU CAN TRUST
      </h1>
      </div>
    </div>
  );
};

export default Gpluspage;



// import React from "react";
// import { motion } from "framer-motion";
// import gplusLogo from "../../image/$RBZAQ7Z.png";

// const Gpluspage = () => {
//   return (
//     <div className="flex items-center justify- w-full h-[100px] px-6 bg-gradient-to-r from-black via-[#800000] via-40% to-white to-95%">
//       {/* Gplus Logo with Animation */}
//       <motion.img
//         src={gplusLogo}
//         alt="Gplus Logo"
//         className="h-12 md:h-16 lg:h-20 xl:h-24 object-contain"
//         initial={{ opacity: 0, scale: 0.8 }} // Start small
//         animate={{ opacity: 1, scale: [1, 1.1, 1] }} // Zoom effect
//         transition={{ duration: 2, ease: "easeInOut", repeat: Infinity }} // Smooth loop
//       />

//       {/* Animated Slogan */}
//       <motion.h1
//         className="text-lg sm:text-2xl md:text-3xl lg:text-4xl font-extrabold text-[#F2DF93] ml-4 tracking-wider 
//         bg-gradient-to-r from-yellow-300 via-yellow-500 to-yellow-700 bg-clip-text  
//         animate-shimmer"
//         initial={{ opacity: 0, x: 90 }}
//         animate={{ opacity: 1, x: 0 }}
//         transition={{ duration: 1, ease: "easeOut" }}
//         whileHover={{ scale: 1.1 }}
//       >
//         INNOVATION YOU CAN TRUST
//       </motion.h1>

//       {/* Custom Tailwind Animation */}
//       <style>
//         {`
//           @keyframes shimmer {
//             0% { opacity: 0.7; transform: scale(1); }
//             50% { opacity: 1; transform: scale(1.05); }
//             100% { opacity: 0.7; transform: scale(1); }
//           }
//           .animate-shimmer {
//             animation: shimmer 2s infinite ease-in-out;
//           }
//         `}
//       </style>
//     </div>
//   );
// };

// export default Gpluspage;


