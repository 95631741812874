import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  PositionDescription,
  updateJobPost,
} from "../../../redux/action/carresAction";
// import { Editor } from "react-draft-wysiwyg";
// import {
//   ContentState,
//   EditorState,
//   convertFromHTML,
//   convertToRaw,
// } from "draft-js";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";
import SideBar from "../Dashboard/SideBar";
// import MyEditor from "../Blogs/BlogsCreate/Editors";
import Editor from "../Blogs/BlogsCreate/Editor";

const UpdateJob = () => {
  const { positionId, isUpdate, loading } = useSelector(
    (state) => state.jobPosition
  );
  console.log(positionId);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [details, setDetails] = useState(positionId?.details || []);
    console.log(details)
  const [name, setName] = useState(positionId?.name);
  const [opening, setOpening] = useState(positionId?.opening);
  const [experience, setExperience] = useState(positionId?.experience);
  const [location, setLocation] = useState(positionId?.location);

  // const initialContent = positionId?.details ? String(positionId.details) : "";
  // const blocksFromHTML = convertFromHTML(initialContent);
  // const contentState = ContentState.createFromBlockArray(
  //   blocksFromHTML.contentBlocks,
  //   blocksFromHTML.entityMap
  // );
  // const [details, setDetails] = useState(
  //   EditorState.createWithContent(contentState)
  // );

  // const onEditorStateChange = (editorState) => {
  //   setDetails(editorState);
  // };
  //   const [editorState, setEditorState] = useState(() =>
  //     EditorState.createEmpty()
  //   );
  //   const arrayToHtml = (array) => array.map((item) => `<p>${item}</p>`).join("");

  //   const [details, setDetails] = useState(
  //     arrayToHtml(positionId?.details || [])
  //   );

  //   // Initialize the editor state based on details
  //   const [editorState, setEditorState] = useState(() => {
  //     const blocksFromHTML = convertFromHTML(details);
  //     const contentState = ContentState.createFromBlockArray(
  //       blocksFromHTML.contentBlocks,
  //       blocksFromHTML.entityMap
  //     );
  //     return EditorState.createWithContent(contentState);
  //   });

  //   useEffect(() => {
  //     // Update the editor state when details changes
  //     const newDetails = arrayToHtml(positionId?.details || []);
  //     if (newDetails) {
  //       const blocksFromHTML = convertFromHTML(newDetails);
  //       const newContentState = ContentState.createFromBlockArray(
  //         blocksFromHTML.contentBlocks,
  //         blocksFromHTML.entityMap
  //       );
  //       setEditorState(EditorState.createWithContent(newContentState));
  //     } else {
  //       setEditorState(EditorState.createEmpty());
  //     }
  //   }, [positionId?.details]);

  const onHandleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateJobPost(id, {
        name: name,
        experience: experience,
        location: location,
        opening: Number(opening),
        details: details, // Convert editorState to HTML before submitting
      })
    );
    if (isUpdate) {
      toast.success("Job updated successfully");
    }
  };

  useEffect(() => {
    dispatch(PositionDescription(id));
  }, [dispatch, id, isUpdate]);

  //   useEffect(() => {
  //     const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  //     setDetails(html);
  //   }, [editorState]);

  useEffect(() => {
    
  }, [positionId]);

  return (
    <div className="font-Montserrat flex">
      <SideBar />
      <div className="p-5 pt-10">
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          <div>
            <h1 className="font-semibold text-base">Posted Date </h1>
            <h1>{positionId?.createdAt}</h1>
          </div>
          <form class="mt-10" onSubmit={onHandleSubmit}>
            <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="floating_first_name"
                  id="floating_first_name"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label
                  for="floating_first_name"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Name
                </label>
              </div>
              <div class="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="floating_last_name"
                  id="floating_last_name"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <label
                  for="floating_last_name"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Location
                </label>
              </div>
            </div>
            <div class="grid md:grid-cols-2 md:gap-6">
              <div class="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="floating_phone"
                  id="floating_phone"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                />
                <label
                  for="floating_phone"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Experience ( years )
                </label>
              </div>
              <div class="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="floating_company"
                  id="floating_company"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  value={opening}
                  onChange={(e) => setOpening(e.target.value)}
                />
                <label
                  for="floating_company"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Opening
                </label>
              </div>
            </div>
            <div
              className="w-full"
              style={{
                border: "1px solid black",
                padding: "10px",
                width: "100%",
                minHeight: "200px",
              }}
            >
              <Editor setDetails={setDetails} details={details} />
              {/* <textarea
                style={{ display: "none" }}
                disabled
                value={draftToHtml(convertToRaw(details.getCurrentContent()))}
              /> */}
            </div>
            <button
              type="submit"
              class=" bg-red mt-10 pl-10 pr-10  text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </form>
        </div>
      )}
      </div>
    </div>
  );
};

export default UpdateJob;
