import React, { useEffect, useState } from "react";
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState, convertToRaw } from "draft-js";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import draftToHtml from "draftjs-to-html";
import SideBar from "../Dashboard/SideBar";
import {
  createJob,
  getAllJobCategory,
} from "../../../redux/action/carresAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IoIosClose } from "react-icons/io";
// import Editors from "../Blogs/BlogsCreate/Editor";
import CreateDepartment from "./CreateDepartment";
import MyEditor from "../Blogs/BlogsCreate/Editors";

const CreateJob = () => {
  const { success, alljobcategory ,error} = useSelector((state) => state.jobPosition);

  const dispatch = useDispatch();
  const [details, setDetails] = useState("");
  const [name, setName] = useState("");
  const [opening, setOpening] = useState("");
  const [file, setFile] = useState("");
  const [experience, setExperience] = useState("");
  const [location, setLocation] = useState("");
  const [skill, setSkills] = useState("");
  const [moreSkill, setMoreSkill] = useState([]);
  const [department, setDepartment] = useState("");
  const [salary, setSalary] = useState("");
  const [jobType, setJobType] = useState("");
  const [errors, setError] = useState("");
  const [toggle, setToggle] = useState(false);

  const addMoreSkills = (e) => {
    if (e.key === "Enter" && skill) {
      e.preventDefault();
      setMoreSkill([...moreSkill, skill]);
      setSkills(" ");
    }
  };

  const onHandleRemove = (index) => {
    const deleteSkill = moreSkill.filter((skill) => skill !== index);
    setMoreSkill(deleteSkill);
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();

    let errorMessage = {};
    if (!name) {
      errorMessage.name = "Job title is required";
    }
    if (!opening) {
      errorMessage.opening = "opening is required";
    }
    if (!experience) {
      errorMessage.experience = "Experience is required";
    }
    if (!location) {
      errorMessage.location = "Location is required";
    }

    if (moreSkill.length === 0) {
      errorMessage.skill = "skills are required";
    }
    if (!file) {
      errorMessage.file = "upload banner is required";
    }

    if (!department) {
      errorMessage.department = "select your department";
    }

    if (!jobType) {
      errorMessage.jobType = "select your job type";
    }

    if (Object.keys(errorMessage).length > 0) {
      setError(errorMessage);
      return;
    }
    const myForm = new FormData();
    myForm.append("name", name);
    myForm.append("opening", opening);
    myForm.append("experience", experience);
    myForm.append("location", location);
    myForm.append("details", details);
    myForm.append("file", file);
    myForm.append("department", department);
    myForm.append("salary", salary);
    myForm.append("skills", moreSkill);
    myForm.append("type", jobType);

    dispatch(createJob(myForm));
  };

  useEffect(() => {
    if (success) {
      toast.success("job created successfull.");
    }
    if(error){
      toast.error(error)
    }
    dispatch(getAllJobCategory());
  }, [dispatch, success,error]);

  return (
    <div className="font-Montserrat flex">
      <SideBar />
      <div className="p-5 relative">
        <div className="flex items-center justify-between">
          <h1 className="font-semibold text-base uppercase">create Job Post</h1>
          <div>
            <button onClick={() => setToggle(!toggle)} className="bg-red pl-10 pr-10 pt-2 pb-2 text-white font-medium text-sm rounded">Create Job Type</button>
            <div className="absolute w-2/6 flex items-center jsutify-center right-20 z-10">{toggle ? <CreateDepartment /> : ""}</div>
          </div>
        </div>
        <form className="mt-10" onSubmit={onHandleSubmit}>
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="floating_first_name"
                id="floating_first_name"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label
                htmlFor="floating_first_name"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Name
              </label>
              {errors.name && <p className="text-red ">{errors.name}</p>}
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="floating_last_name"
                id="floating_last_name"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
              <label
                for="floating_last_name"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Location
              </label>
              {errors.location && <p className="text-red ">{errors.location}</p>}
            </div>
          </div>
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="floating_phone"
                id="floating_phone"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
              />
              <label
                for="floating_phone"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Experience ( years )
              </label>
              {errors.experience && (
                <p className="text-red ">{errors.experience}</p>
              )}
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="floating_company"
                id="floating_company"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                value={opening}
                onChange={(e) => setOpening(e.target.value)}
              />
              <label
                for="floating_company"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Opening
              </label>
              {errors.opening && <p className="text-red ">{errors.opening}</p>}
            </div>
          </div>

          {/*  */}

          <div className="grid md:grid-cols-2 md:gap-6">
            <div>
              <select
                name=""
                id=""
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer capitalize"
                onChange={(e) => setJobType(e.target.value)}
              >
                <option value="Select product category">
                  Job Type (Remote)
                </option>
                <option value="Full Time (work from office)">
                  Full Time (work from office)
                </option>
              </select>
              {errors.jobType && <p className="text-red ">{errors.jobType}</p>}
            </div>

            <div>
              <select
                name=""
                id=""
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer capitalize"
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value="Select product category" disabled>
                  Select Department
                </option>
                {alljobcategory &&
                  alljobcategory.map((cate) => {
                    return (
                      <option key={cate._id} value={cate._id} className="capitalize">
                        {cate.name}
                      </option>
                    );
                  })}
              </select>
              {errors.department && (
                <p className="text-red ">{errors.department}</p>
              )}
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="number"
                name="floating_company"
                id="floating_company"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                value={salary}
                onChange={(e) => setSalary(e.target.value)}
              />
              <label
                for="floating_company"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Salary (Optional)
              </label>
            </div>
          </div>

          {/* skils required */}
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="skill"
                id="floating_email"
                value={skill}
                onChange={(e) => setSkills(e.target.value)}
                onKeyDown={addMoreSkills}
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
              />
              <label
                for="floating_email"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Skills
                <span className="text-red ml-2">*</span>
              </label>
              {errors.skill && <p className="text-red ">{errors.skill}</p>}

              <div className="flex flex-wrap">
                {moreSkill.map((item, index) => {
                  return (
                    <ul key={index} className="flex p-1">
                      <li className="border p-1 rounded-full flex items-center uppercase">
                        {item}
                        <IoIosClose
                          size={20}
                          color="red"
                          className="cursor-pointer"
                          onClick={() => onHandleRemove(item)}
                        />
                      </li>
                    </ul>
                  );
                })}
              </div>
            </div>
          </div>

          {/* upload banner */}
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-5 group">
              <input
                accept="image/png, image/gif, image/jpeg"
                type="file"
                name="floating_phone"
                id="floating_phone"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(e) => setFile(e.target.files[0])}
              />
              <label
                for="floating_phone"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                upload banner
              </label>
              {errors.file && <p className="text-red ">{errors.file}</p>}
            </div>
          </div>
          <div
            className="w-full mt-5"
            style={{
              border: "1px solid black",
              padding: "10px",
              width: "100%",
              minHeight: "200px",
            }}
          >
            <MyEditor setDetails={setDetails} />
            {errors.details && <p className="text-red ">{errors.details}</p>}
          </div>
          <button
            type="submit"
            className=" bg-red text-white mt-10 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto pl-10 pr-10 pt-2 pb-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateJob;   
