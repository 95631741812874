import React from "react";
import { Link } from "react-router-dom";
import { premiumData } from "./PremiumObj";
// import CounterDetails from "../CounterDetails/counterDetails";

const PremiumProduct = () => {
  return (
    <div className="w-full  h-full pb-[20px] md:mb-[100px]  pl-3 md:pl-8 pr-3 md:pr-8 bg-white">
      <div className="flex flex-col items-center justify-center w-full h-full">
        {/* <h1 className="font-semibold text-xl md:text-3xl text-center uppercase mt-5">
          G-plus Premium Appliances
        </h1>
        <p className="font-medium pt-2 text-center text-[10px] md:text-sm">
          Discover G-plus: where cutting-edge technology meets elegant design,
          delivering unmatched performance and energy savings for your home.
        </p> */}
        



        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-4 md:gap-6 mt-4 md:mt-10 px-4">
  {premiumData &&
    premiumData.map((item) => (
      <div
        key={item.id}
        className="w-full max-w-[280px] flex flex-col items-center justify-between"
      >
        {/* Image */}
        <div className="w-full h-[200px] sm:h-[250px] md:h-[300px] flex items-center justify-center">
          <img
            className="w-full h-full object-cover rounded-lg"
            src={item.imageUrl}
            alt=""
          />
        </div>

        {/* Button */}
        <div className="pt-3">
          <Link to={item.url}>
            <button className="w-16 sm:w-20 md:w-32 lg:w-48 py-1 sm:py-2 bg-[#9F2725] font-semibold text-xs sm:text-sm text-white rounded-full">
              View
            </button>
          </Link>
        </div>
      </div>
    ))}
</div>



          {/* <div className="w-[280px] h-[250px] flex items-center justify-center flex-col ">
            <img src={ref} alt="" />
            <Link
              className="pt-2"
              to="https://gshoppi.com/product/GPLUS-528-L-FRENCH-DOOR-BOTTOM-FREZZER-Refrigerator-(Silver-GM-528W)/65eae981145ae609d59a8577"
            >
              <button className="pl-10 pr-10 pt-2 pb-2 bg-[#9F2725] text-white rounded ">
                Buy Now
              </button>
            </Link>
          </div>
          <div className="w-[280px] h-[250px] flex items-center justify-center flex-col ">
            <img src={dishwasher} alt="" />
            <Link
              className="pt-2"
              to="https://gshoppi.com/product/GPLUS-DISH-WASHER-16-PLACE-SETTINGS-black-(GM-DW-ULTRAX16)/658bbdfb0d16b48350c46ce4"
            >
              <button className="pl-10 pr-10 pt-2 pb-2 bg-[#9F2725] text-white rounded ">
                Buy Now
              </button>
            </Link>
          </div>
          <div className="w-[280px] h-[250px] flex items-center justify-center flex-col ">
            <img src={tv85} alt="" />
            <Link
              className="pt-2"
              to="https://gshoppi.com/product/GPLUS-QLED-214-Cm-(85-Inches)-Ultra-HD-4K/65d9baf529cf4480e2ffdabc"
            >
              <button className="pl-10 pr-10 pt-2 pb-2 bg-[#9F2725] text-white rounded ">
                Buy Now
              </button>
            </Link>
          </div> */}
        
      </div>
    </div>
  );
};

export default PremiumProduct;
