import tv85 from "../../image/85 inches1.jpeg";
import Aircooler from "../../image/sdfsdf.jpg";
import Topwashing from "../../image/Untitled-1.jpg";
import washing_machine from "../../image/17.jpg";
import dishwasher from "../../image/09.jpg";
import ref from "../../image/08.jpg";
import Semiwashing from "../../image/top load.png";
import secondtv from "../../image/uiuiuiu.jpg";
// import Aircooler from "../../image/Air Conditioner1.5.jpg";
// import cooler1 from "../../image/cooler1.png";

export const premiumData = [
    {
        id: 0,
        url: "https://gshoppi.com/product/6581387da02a3e7d21ac6d07",
        imageUrl: Topwashing
    },
    {
        id: 1,
        url: "https://gshoppi.com/product/GPLUS-DISH-WASHER-16-PLACE-SETTINGS-black-(GM-DW-ULTRAX16)/658bbdfb0d16b48350c46ce4",
        imageUrl:  dishwasher
    },
    {
        id: 2,
        url: "https://gshoppi.com/product/GPLUS-528-L-FRENCH-DOOR-BOTTOM-FREZZER-Refrigerator-(Silver-GM-528W)/65eae981145ae609d59a8577",
        imageUrl: ref
    },
    {
        id: 3,
        url: "https://gshoppi.com/product/Gplus-8-kg-Fully-Automatic-Front-Load-Washing-Machine-silver/6581475ca02a3e7d21ac6e81",
        imageUrl:washing_machine  
    },
    {
            id: 4,
            url: "https://gshoppi.com/product/GPLUS-7-KG-Top-Load-Fully-Automatic-washing-machine/65e84ff3eb6571c81fe44d4a",
            imageUrl:Semiwashing
        },
    
    {
        id: 5,
        url: "https://gshoppi.com/product/GPLUS-QLED-214-Cm-(85-Inches)-Ultra-HD-4K/65d9baf529cf4480e2ffdabc",
        imageUrl: tv85
    },

     
    {
        id: 6,
        url: "https://gshoppi.com/product/GPLUS-AIR-COOLER-65-LITERS-PREMIUM-MODEL/67879a838b2fb7b6927d5068",
        imageUrl: Aircooler
    },
    {
        id: 7,
        url: "https://gshoppi.com/product/Gplus-55-inches-(139-cm)-QLED-ultra-hd(4k)/6777a8a9dfe679a305032bf6",
        imageUrl:secondtv
    },
    // {
    //     id: 5,
    //     url: "https://gshoppi.com/product/GPLUS-QLED-214-Cm-(85-Inches)-Ultra-HD-4K/65d9baf529cf4480e2ffdabc",
    //     imageUrl:refred
    // },
    // {
    //     id: 7,
    //     url: "https://gshoppi.com/product/GPLUS-QLED-214-Cm-(85-Inches)-Ultra-HD-4K/65d9baf529cf4480e2ffdabc",
    //     imageUrl: cooler1
    // },
]