import React from "react";

const TermsCondition = () => {
  return (
    <div className="pl-3 pr-3 sm:pl-10 sm:pr-10 font-roboto bg-white text-black">
      <h1 className="font-bold text-2xl text-center">Terms & Conditions</h1>
      <div className="w-full h-[2px] bg-black mt-5 mb-5"></div>
      <div>
        <h1 className="font-bold text-lg mt-2 mb-2">Terms of use</h1>
        <p className="font-normal text-base">
          Access to and use of Garudamartindia.com and the products and service
          available through the website are subject to the following terms,
          conditions and notices (“Terms of Service”). By browsing through these
          Terms of Service and using the services provided by our website
          (www.Garudamartindia.com), you agree to all Terms of Service along
          with the Privacy Policy on our website, which may be updated by us
          from time to time. Please check this page regularly to take notice of
          any changes we may have made to the Terms of Service.
        </p>
        <p className="mt-5 font-normal text-base">
          We reserve the right to review and withdraw or amend the services
          without notice. We will not be liable if for any reason this Website
          is unavailable at any time or for any period. From time to time, we
          may restrict access to some parts or this entire Website.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="font-bold text-lg mt-2 mb-2">Introduction</h1>
        <p className="font-normal text-base">
          The domain name www.garudamartindia.com is a site operated by Seminole
          Tech., a firm incorporated under laws of India with our registered
          office at S-145, 4th Floor, South Block, MANIPAL CENTRE 47, Dickenson
          Road Bangalore Bangalore KA 560042 IN.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="font-bold text-lg mt-2 mb-2">Services</h1>
        <p className="font-normal text-base">
          Garuda Mart is an online retailer of electronics. It allows customers
          to purchase a variety of products. Upon placing an order,
          garudamartindia.com shall ship the product to you and be entitle to
          its payment for the service.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="font-bold text-lg mt-2 mb-2">
          Third Party Websites and Content
        </h1>
        <p className="font-normal text-base">
          Our website provides links for sharing our content on Facebook,
          twitter and other such third party website. These are only for sharing
          and/or listing purpose and we take no responsibility of the third
          party websites and/or their contents listed on our website
          (www.Garudamartindia.com ) and disclaim all our liabilities arising
          out of any or all third party websites. We disclaim all liabilities
          and take no responsibility for the content that may be posted on such
          third party websites by the users of such websites in their personal
          capacity on any of the above mentioned links for sharing and/or
          listing purposes as well as any content and/or comments that may be
          posted by such user in their personal capacity on any official webpage
          of garudamartindia.com on any social networking platform.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="font-bold text-lg mt-2 mb-2">
          The exactness of the Product
        </h1>
        <p className="font-normal text-base">
          The images of the items on the website are for illustrative purposes
          only. Although we have made every effort to display the colours
          accurately, we cannot guarantee that your computer’s display of the
          colours accurately reflect the colour of the items. Your items may
          vary slightly from those images. All sizes and measurements of items
          are approximate; however we do make every effort to ensure they are
          accurate as possible. We take all reasonable care to ensure that all
          details, descriptions and prices of items are as accurate as possible.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="font-bold text-lg mt-2 mb-2">Pricing</h1>
        <p className="font-normal text-base">
          We ensure that all details of prices appearing on the website are
          accurate, however errors may occur. If we discover an error in the
          price of any goods which you have ordered, we will inform you of this
          as soon as possible. If we are unable to contact you we will treat the
          order as cancelled. If you cancel and you have already paid for the
          goods, you will receive a full refund.
        </p>
        <p className="font-normal text-base mt-5">
          Additionally, prices for items may change from time to time without
          notice. However, these changes will not affect orders that have
          already been dispatched. The price of an item includes GST (or similar
          sales tax) at the prevailing rate for which we are responsible as a
          seller. Please note that the prices listed on the website are only
          applicable for items purchased on the website and not through any
          other source.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="font-bold text-lg mt-2 mb-2">Payment </h1>
        <p className="font-normal text-base">
          Upon receiving your order we carry out a standard pre-authorization
          check on your payment card to ensure there are sufficient funds to
          fulfill the transaction. Goods will not be dispatched until this
          pre-authorization check has been completed. Your card will be debited
          once the order has been accepted. For any further payment related
          queries, please check our FAQs on Payment Mode.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="font-bold text-lg mt-2 mb-2">Delivery </h1>
        <p className="font-normal text-base">
          You will be given various options for delivery of items during the
          order process. The options available to you will vary depending on
          where you are ordering from. An estimated delivery time is displayed
          on the order summary page. On placing your order, you will receive an
          email containing a summary of the order and also the estimated
          delivery time to your location. Sometimes, delivery may take longer
          due to unforeseen circumstances. In such cases, we will proactively
          reach out to you by e-mail and SMS. However, we will not be able to
          compensate for any mental agony caused due to delay in delivery.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="font-bold text-lg mt-2 mb-2">Returns & Refund </h1>
        <p className="font-normal text-base">
          If you change your mind about any items purchased you can return them
          to us. For more information on Returns and Refund, please refer to our
          Return Policy
        </p>
      </div>

      <div className="mt-5">
        <h1 className="font-bold text-lg mt-2 mb-2">
          Intellectual Property Rights{" "}
        </h1>
        <p className="font-normal text-base">
          All and any intellectual property rights in connection with the
          products shall be owned absolutely by Seminole Tech.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="font-bold text-lg mt-2 mb-2">Law and Jurisdiction</h1>
        <p className="font-normal text-base">
          These terms shall be governed by and constructed in accordance with
          the laws of India without reference to conflict of laws principles and
          disputes arising in relation hereto shall be subject to the exclusive
          jurisdiction of the courts at Bangalore.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="font-bold text-lg mt-2 mb-2">Indemnification</h1>
        <p className="font-normal text-base">
          You agree to indemnify, defend and hold harmless the firm, officers,
          employees, consultants, agents, and affiliates, from any and all third
          party claims, liability, damages or costs arising from your use of
          this website, your breach of these Terms of Service, or infringement
          of any intellectual property right.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="font-bold text-lg mt-2 mb-2">Violation & Termination</h1>
        <p className="font-normal text-base">
        You agree that the Company may, in its sole discretion and without prior notice, terminate your access to the website and block your future access if we determine that you have violated these Terms of Service or any other policies. If you or the Company terminates your use of any service, you shall still be liable to pay for any service that you have already ordered till the time of such termination. –
        </p>
        <p className="font-normal text-base mt-5">If you have any questions, comments or requests regarding our Terms of Service or the website please contact us at support@garudamartindia.com.</p>
      </div>
    </div>
  );
};

export default TermsCondition;
