import React from "react";
import "./Home.css";
import CounterDetails from "../CounterDetails/counterDetails";
import Header from "../Header/Header";
import Graph from "../Graph/Graph";
import PremiumProduct from "../gplus-premium/premiumProduct";
// import JoyEbike from "../Joy-e-bike/JoyEbike";
import Gpluspage from "../Gpluspage/Gpluspage";
import Digital from "../DigitalPlateForm/Digital";

const Home = () => {

  return (
    <div className="font-OpenSans bg-white text-black">
      <Header />
      <CounterDetails />

      <Graph />
      {/* <div className="w-full h-[0.5px] bg-[#9F2725] sm:bg-white mt-[100px]"></div> */}
      <Gpluspage />
      <PremiumProduct />
      {/* <div className="w-full h-[0.5px] bg-[#9F2725] sm:bg-white mt-5  mb-5"></div> */}
      
      {/* <div className="w-full h-[0.5px] bg-[#9F2725] sm:bg-white mt-5"></div> */}
      <Digital />

      
      
      {/* [480px] h-[320px] */}
     

     

     

     

     
      {/* <div className="w-full flex items-center justify-center">
        <div className="w-full sm:w-2/3 bg-gray h-60 md:h-96 mt-10 mb-10 rounded shadow-2xl m-5 ">
          <iframe
            className="w-full h-full rounded"
            src="https://www.youtube.com/embed/y4wcRNA5_kg?si=P0WTHdZxqMJGp2eb"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
