import React from "react";
import garpg from "../../image/Garudayaan/fture.jpg"

const Future = () => {
  return (
    <div className="pl-3 sm:pl-10 pr-3 sm:pr-10 pt-5 sm:pt-10 pb-10 font-Montserrat space-y-4 bg-white text-black flex items-center justify-center flex-col">
        <img  className="h-[400px] "  src={garpg} alt="" />
      <p className="font-medium text-[12px] sm:text-base">
        The next five years hold even greater promise for the company, as it is
        projected to achieve significant growth, surpassing its previous
        achievements. The focus will be on leveraging the momentum built over
        the past years to further expand market reach, enhance product
        offerings, and tap into new opportunities. The company plans to
        diversify and innovate, responding to evolving customer needs with
        cutting-edge technology and solutions.
      </p>
      <p className="font-medium text-[12px] sm:text-base">
        Strategic investments in research and development, along with a robust
        marketing approach, will position the company for continued success.
        Expansion into new regions and customer segments is a key part of the
        strategy, ensuring a broader footprint and higher revenue streams. The
        growth plans are ambitious yet grounded in the company’s proven ability
        to execute and adapt, setting the stage for long-term success and
        establishing itself as a leading player in the industry.
      </p>
      <p className="font-medium text-[12px] sm:text-base">
        This forward-looking phase represents a pivotal moment, where the
        company aims to transform its strong foundation into even greater
        achievements, driving both financial and operational excellence
      </p>
    </div>
  );
};

export default Future;
