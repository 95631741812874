import React, { useState } from "react";
import "froala-editor/css/froala_editor.pkgd.min.css"; // Editor CSS
import "froala-editor/css/froala_style.min.css"; // Additional styling
import "froala-editor/js/plugins.pkgd.min.js"; // Editor plugins
import FroalaEditorComponent from "react-froala-wysiwyg"; // Froala Editor component
import { useDispatch } from "react-redux";
import { uploadBlogImage } from "../../../../redux/action/BlogsAction";
import "./EditorStyles.css"


const MyEditor = ({ setDetails }) => {
  const dispatch = useDispatch();
  const [content, setContent] = useState(""); // State to manage editor content

  const handleModelChange = (model) => {
    setContent(model); // Update the state when editor content changes
    setDetails(model);
  };

  const handleImageInsert = (editor) => {
    editor.events.on('froalaEditor.image.inserted', function ($img) {
        // Apply inline styles directly to the image
        const alignment = editor.image.align() || 'center'; // Default to 'center'
        $img.css({
          'display': 'block',
          'margin-left': 'auto',
          'margin-right': 'auto',
          'float': alignment === 'left' ? 'left' : (alignment === 'right' ? 'right' : 'none')
        });
      });
  };

  const handleImageUpload = async (images) => {
    if (images.length) {
      const formData = new FormData();
      formData.append("file", images[0]); // Attach the first image in the array

      try {
        const response = await dispatch(uploadBlogImage(formData)); // Call your Redux action
        const { link } = response; // Make sure `uploadBlogImage` returns an object with a `link` property
        return { link }; // Return the URL in the format Froala expects
      } catch (error) {
        console.error("Image upload failed:", error);
        return { error: "Image upload failed" }; // Return an error object if the upload fails
      }
    }
    return {}; // Ensure a default return value
  }; 

  // console.log(content);

  return (
    <div className="w-full" id="froala-editor">
      <FroalaEditorComponent
        className="w-full"
        model={content}
        onModelChange={handleModelChange}
        config={{
          placeholderText: "Edit Your Content Here!",
          charCounterCount: true,
          imageDefaultWidth: "100%",
          imageUpload: true, // Enable image upload
          videoUpload: true, // Enable video upload
          toolbarInline: false, // Disable inline toolbar
          imageUploadURL: "/api/blogs/upload", // URL for image upload
          imageUploadParams: {
            // Extra params can be added here if required
          },
          toolbarVisibleWithoutSelection:true,
          imageUploadMethod: "POST", // Use POST method for image upload
          imageDefaultAlign: "center", // Set default image alignment
          imageDefaultDisplay: "block",
     

          events: {
            "froalaEditor.image.beforeUpload": function (e, editor, images) {
              if (images.length) {
                handleImageUpload(images).then((response) => {
                  if (response.link) {
                    // Insert the image into the Froala editor using the uploaded URL
                    editor.image.insert(response.link, true, null, null, null,{
                      style: "width:100%; display:block; margin:0 auto;" // Center alignment
                    })
                    // Ensure image is placed correctly in the content
                    editor.events.focus(); // Force focus to refresh editor content
                  } else if (response.error) {
                    console.error(response.error); // Log or display upload error
                  }
                });
                return false; // Prevent Froala's own upload behavior
              }
            },
            'froalaEditor.image.inserted': handleImageInsert,

            "froalaEditor.image.error": function (error) {
              console.log("Image upload error:", error);
            },
            "froalaEditor.contentChanged": function () {
              // Optional: Additional handling if needed when content changes
            },
          },
        }}
      />
      <div>
        <h3>Editor Content Preview:</h3>
        <div dangerouslySetInnerHTML={{ __html: content }}  />
      </div>
    </div>
  );
};

export default MyEditor;
